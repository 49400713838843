import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    contentHeaderWrapper: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(0),
        backgroundColor: 'transparent',
        boxShadow: 'none',
    },
    formInputItem: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing(3),
    },
    inputLabel: {
        fontSize: theme.typography.pxToRem(12),
        marginBottom: theme.spacing(0.5),
    },
    submitButton: {
        width: 150,
    },
    sideImageWrapper: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
    },
    successMessage: {
        fontWeight: 700,
        color: theme.palette.success.main,
    },
    errorMessage: {
        fontWeight: 700,
        color: theme.palette.error.main,
    },
}));
