import React, { useState } from 'react';
import { useFormik } from 'formik';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as PropTypes from 'prop-types';
import * as yup from 'yup';
import { Grid, InputLabel, TextField, Typography } from '@material-ui/core';

import client from '$landing-page/client';
import Button from '$landing-page/components/button';
import BigContentHeaderLayout from '$landing-page/layouts/big-content-header-layout';

import useStyles from './styles';

const formValidationSchema = yup.object({
    firstName: yup.string().required('First Name is required.'),
    lastName: yup.string().required('Last Name is required.'),
    workEmail: yup.string().email('Please enter a valid work email address.').required('Work email address is required.'),
    organizationName: yup.string().required('Organization name is required.'),
    demoTopic: yup.string(),
});

const query = graphql`
    query bookDemoPage {
        image: file(relativePath: { eq: "book-a-demo-page-image.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FIXED, width: 350, quality: 100, placeholder: NONE)
            }
        }
    }
`;

const API_RESPONSE_STATUS = { error: 'ERROR', success: 'SUCCESS' };

const BookADemoIndexPage = (props) => {
    const { location } = props;
    const [responseStatus, setResponseStatus] = useState(null);

    const queryData = useStaticQuery(query);

    const classes = useStyles();

    const handleFormSubmit = (values) => {
        const { firstName, lastName, workEmail, organizationName, demoTopic } = values;
        setResponseStatus(null);
        return client.api
            .post('/sales/person/book_demo/', {
                first_name: firstName,
                last_name: lastName,
                email: workEmail,
                org_name: organizationName,
                topic: demoTopic,
            })
            .then(() => {
                setResponseStatus(API_RESPONSE_STATUS.success);
                formik.resetForm();
            })
            .catch(() => {
                setResponseStatus(API_RESPONSE_STATUS.error);
            });
    };

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            workEmail: '',
            organizationName: '',
            demoTopic: '',
        },
        validationSchema: formValidationSchema,
        onSubmit: handleFormSubmit,
    });

    return (
        <BigContentHeaderLayout
            pathname={location.pathname}
            title="Book a Demo"
            description="Let us know a bit about yourself and we will get in touch shortly."
            classes={{ contentHeaderWrapper: classes.contentHeaderWrapper }}
        >
            <form onSubmit={formik.handleSubmit}>
                <Grid container>
                    <Grid item container xs={12} md={6} direction="column">
                        <Grid item className={classes.formInputItem}>
                            <InputLabel htmlFor="firstName" className={classes.inputLabel}>
                                First Name*
                            </InputLabel>
                            <TextField
                                size="small"
                                variant="outlined"
                                id="firstName"
                                name="firstName"
                                value={formik.values.firstName}
                                onChange={formik.handleChange}
                                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                helperText={formik.touched.firstName && formik.errors.firstName}
                            />
                        </Grid>
                        <Grid item className={classes.formInputItem}>
                            <InputLabel htmlFor="lastName" className={classes.inputLabel}>
                                Last Name*
                            </InputLabel>
                            <TextField
                                size="small"
                                variant="outlined"
                                id="lastName"
                                name="lastName"
                                value={formik.values.lastName}
                                onChange={formik.handleChange}
                                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                helperText={formik.touched.lastName && formik.errors.lastName}
                            />
                        </Grid>
                        <Grid item className={classes.formInputItem}>
                            <InputLabel htmlFor="workEmail" className={classes.inputLabel}>
                                Work Email*
                            </InputLabel>
                            <TextField
                                size="small"
                                variant="outlined"
                                id="workEmail"
                                name="workEmail"
                                value={formik.values.workEmail}
                                onChange={formik.handleChange}
                                error={formik.touched.workEmail && Boolean(formik.errors.workEmail)}
                                helperText={formik.touched.workEmail && formik.errors.workEmail}
                            />
                        </Grid>
                        <Grid item className={classes.formInputItem}>
                            <InputLabel htmlFor="organizationName" className={classes.inputLabel}>
                                Organization Name*
                            </InputLabel>
                            <TextField
                                size="small"
                                variant="outlined"
                                id="organizationName"
                                name="organizationName"
                                value={formik.values.organizationName}
                                onChange={formik.handleChange}
                                error={formik.touched.organizationName && Boolean(formik.errors.organizationName)}
                                helperText={formik.touched.organizationName && formik.errors.organizationName}
                            />
                        </Grid>
                        <Grid item className={classes.formInputItem}>
                            <InputLabel htmlFor="demoTopic" className={classes.inputLabel}>
                                Tell us a bit about your research:
                            </InputLabel>
                            <TextField
                                size="small"
                                multiline
                                rows="5"
                                variant="outlined"
                                id="demoTopic"
                                name="demoTopic"
                                value={formik.values.demoTopic}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                        <Grid item className={classes.formInputItem}>
                            <Button
                                variant="contained"
                                className={classes.submitButton}
                                onClick={formik.handleSubmit}
                                disabled={formik.isSubmitting}
                            >
                                {formik.isSubmitting ? 'Booking...' : 'Book'}
                            </Button>
                        </Grid>
                        <Grid item className={classes.formInputItem}>
                            {responseStatus === API_RESPONSE_STATUS.success && (
                                <Typography className={classes.successMessage}>
                                    Your demo is booked. We will be in touch shortly with details.
                                </Typography>
                            )}
                            {responseStatus === API_RESPONSE_STATUS.error && (
                                <Typography className={classes.errorMessage}>
                                    Something went wrong. Please try again or email us at sales@ethicadata.com.
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.sideImageWrapper}>
                        <GatsbyImage image={queryData.image.childImageSharp.gatsbyImageData} alt="Book a Demo" />
                    </Grid>
                </Grid>
            </form>
        </BigContentHeaderLayout>
    );
};

BookADemoIndexPage.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
};

export default BookADemoIndexPage;
